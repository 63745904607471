import Component from '../../js/core/Component';

export default class Antispam extends Component {
    prepare() {
        const fakeDom = document.createElement('div')
        fakeDom.innerHTML = this.element.innerText.replace(/&lt;/g, '<').replace(/&gt;/g, '>')

        // get attributes
        const key = fakeDom.querySelector('label[for="agreeemail"] strong').innerText
        const name = fakeDom.querySelector('.anti-fill').getAttribute('name')

        // append
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = key
        this.element.parentNode.appendChild(input)
    }
}