import Component from '../core/Component'
import Scroll, { getScrollTop } from '../services/Scroll'
import enquire from 'enquire.js'
import { queries } from '../core/config'

export const STATES = {
    STICKY: 'is-sticky',
}

export default class Sticky extends Component {
    constructor(element, options = { top: 40, media: 'xlargeUp' }) {
        super(element, {})

        const top = parseInt(this.element.dataset.stickyTop)

        if (!isNaN(top)) {
            this.options.top = top
        }
        this.options = options
        this.enquireHandler = null

        if (this.element.dataset.media) {
            this.options.media = this.element.dataset.media
        }

        this.isSticky = false
        this.isReady = false

        if (this.options.media) {
            this.enquireQuery =
                this.options.media in queries ? queries[this.options.media] : this.options.media
            this.enquireHandler = {
                match: this.attach,
                unmatch: this.detach,
            }
        }
    }

    prepare() {
        if (this.enquireHandler) {
            enquire.register(this.enquireQuery, this.enquireHandler)
        }
    }

    destroy() {
        if (this.enquireHandler) {
            enquire.unregister(this.enquireQuery, this.enquireHandler)
        }
    }

    attach = () => {
        this.parent = this.element.parentNode

        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)

        setTimeout(() => {
            this.resize()
            this.isReady = true
            this.render()
        }, 0)
    }

    detach = () => {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
        this.element.removeAttribute('style')
        this.element.classList.remove(STATES.STICKY)
        this.isReady = false
    }

    handleScroll = () => {
        if (!this.isReady) {
            return
        }

        this.render()
    }

    handleResize = () => {
        if (!this.isReady) {
            return
        }

        this.resize()
    }

    resize() {
        this.element.style.width = 'auto'

        const scrollTop = getScrollTop()

        const parentBox = this.parent.getBoundingClientRect()
        this.parentBox = {
            height: parentBox.height,
            width: parentBox.width,
            top: scrollTop + parentBox.top,
        }

        const elementBox = this.element.getBoundingClientRect()
        this.elementBox = {
            height: elementBox.height,
            width: elementBox.width,
            top: scrollTop + elementBox.top,
        }

        this.element.style.width = `${this.elementBox.width}px`
    }

    render() {
        const offset = getScrollTop() - this.parentBox.top + this.options.top
        const max = this.parentBox.height - this.elementBox.height

        if (offset < 0) {
            if (this.isSticky) {
                this.element.classList.remove(STATES.STICKY)
                this.isSticky = false
                this.element.style.transform = 'translateY(0px)'
            }
        } else if (offset > max) {
            if (this.isSticky) {
                this.element.classList.remove(STATES.STICKY)
                this.isSticky = false
                this.element.style.transform = `translateY(${max}px)`
            }
        } else {
            if (!this.isSticky) {
                this.element.classList.add(STATES.STICKY)
                this.isSticky = true
            }
            this.element.style.transform = `translateY(${Math.round(offset)}px)`
        }
    }
}
