import Component from '../core/Component.js'
import Flickity from 'flickity'
import enquire from 'enquire.js'
import { queries } from '../core/config'
import { toggleClass, queryAll } from '../utils/dom'
import Scroll from '../services/Scroll'
import debounce from 'lodash/debounce'

export const defaults = {
    cellSelector: '[data-ref="item"]',
    prevNextButtons: false,
    pageDots: false,
    cellAlign: 'left',
    groupCells: true,
    autoplay: true,
    lazyLoad: 2,
    responzive: true,
    resize: true,
    percentPosition: false,
    selectedAttraction: 0.1,
    friction: 0.8,
    contain: true,
    media: '',
}

export const STATES = {
    READY: 'is-ready',
    DISABLED: 'is-disabled',
    ACTIVE: 'is-active'
}

export default class Slider extends Component {
    constructor(element, options = {}) {
        super(element, defaults)

        this.ref = {
            slider: null,
            items: [],
            prev: null,
            next: null,
            dots: null
        }

        this.options = {...defaults, ...options}
        this.flickity = null
        this.index = 0
        this.isDragging = false
        this.dragEndTimer = null

        this.enquireHandler = null

        if (this.options.media) {
            this.enquireQuery = this.options.media in queries ? queries[this.options.media] : this.options.media
            this.enquireHandler = {
                match: this.attach,
                unmatch: this.detach
            }
        }

        this.handleResize = debounce(this.handleResize, 300)
    }

    prepare() {
        if (this.enquireHandler) {
            enquire.register(this.enquireQuery, this.enquireHandler)
        } else {
            this.attach()
        }

        this.ref.slider.addEventListener('click', this.handleClick)
    }

    destroy() {
        if (this.enquireHandler) {
            enquire.unregister(this.enquireQuery, this.enquireHandler)
        } else {
            this.detach()
        }
    }

    attach = () => {
        if (this.flickity) {
            return
        }

        this.element.classList.add(STATES.READY)

        this.flickity = new Flickity(this.ref.slider, this.options)

        this.ref.prev?.addEventListener('click', this.handlePreviousClick)
        this.ref.next?.addEventListener('click', this.handleNextClick)

        this.flickity.on('change', this.handleChange)
        this.flickity.on('dragStart', this.handleDragStart)
        this.flickity.on('dragEnd', this.handleDragEnd)

        this.resize()

        Scroll.on('resize', this.handleResize)

        this.handleChange(0)
    }

    detach = () => {
        if (this.flickity) {
            this.flickity.destroy()
            this.flickity = null
        }

        this.element.classList.remove(STATES.READY)

        this.ref.prev?.removeEventListener('click', this.handlePreviousClick)
        this.ref.next?.removeEventListener('click', this.handleNextClick)

        Scroll.off('resize', this.handleResize)
    }

    handleClick = event => {
        if (this.isDragging) {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    handleDragStart = () => {
        clearTimeout(this.dragEndTimer)
        this.isDragging = true
    }

    handleDragEnd = () => {
        clearTimeout(this.dragEndTimer)
        this.dragEndTimer = setTimeout(() => {
            this.isDragging = false
        }, 100)
    }

    handleNextClick = event => {
        event.preventDefault()
        this.next()
    }

    handlePreviousClick = event => {
        event.preventDefault()
        this.previous()
    }

    handleChange = index => {
        toggleClass(this.ref.prev, STATES.DISABLED, index === 0)
        toggleClass(this.ref.next, STATES.DISABLED, index >= this.flickity.slides.length - 1)

        this.dots?.forEach((dot, dotIndex) => toggleClass(dot, STATES.ACTIVE, dotIndex === index))

        this.index = index
    }

    handleResize = () => {
        this.resize()
    }

    resize() {
        if (this.ref.dots?.length > 0) {
            this.ref.dots.innerHTML = this.flickity.slides.map(element => '<span class="dot" data-ref="dot"></span>').join('')
            this.dots = queryAll('[data-ref="dot"]', this.ref.dots)
            this.dots.forEach((dot, index) => toggleClass(dot, STATES.ACTIVE, this.flickity.selectedIndex === index))
        }
    }

    next() {
        this.flickity.next()
    }

    previous() {
        this.flickity.previous()
    }
}
