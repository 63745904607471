import Component from '../core/Component'
import enquire from 'enquire.js'
import { queries } from '../core/config'

const STATES = {
    ACTIVE: 'is-searchActive',
}

const Events = {
    Open: 'open',
    Close: 'close',
    Toggle: 'toggle',
}

export default class SiteHeader extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            inner: null,
        }

        this.enquireHandlers = {
            match: () => this.close(),
        }
    }

    prepare() {
        enquire.register(queries.mediumUp, this.enquireHandlers)
    }

    destroy() {
        enquire.unregister(queries.mediumUp, this.enquireHandlers)
    }

    open() {
        this.ref.inner.classList.add(STATES.ACTIVE)
        this.emit(Events.Open)
    }

    close() {
        this.ref.inner.classList.remove(STATES.ACTIVE)
        this.emit(Events.Close)
    }

    toggle() {
        this.ref.inner.classList.toggle(STATES.ACTIVE)
        this.emit(Events.Toggle)
    }
}
