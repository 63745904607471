import Component from '../core/Component.js'
import Flickity from 'flickity'
import enquire from 'enquire.js'
import { queries } from '../core/config'
import { toggleClass, queryAll } from '../utils/dom'
import Scroll from '../services/Scroll'
import debounce from 'lodash/debounce'

export const defaults = {
    cellSelector: '[data-ref="item"]',
    prevNextButtons: false,
    pageDots: true,
    // groupCells: true,
    autoplay: true,
    lazyLoad: 2,
    responzive: true,
    resize: true,
    wrapAround: true,
    percentPosition: false,
    selectedAttraction: 0.1,
    friction: 0.8,
    contain: true,
    media: '',
}

export const STATES = {
    READY: 'is-ready',
    DISABLED: 'is-disabled',
    ACTIVE: 'is-active',
}

export default class SuggestedSlider extends Component {
    constructor(element, options = {}) {
        super(element, defaults)

        this.ref = {
            slider: null,
            items: [],
            dots: null,
        }

        this.options = { ...defaults, ...options }
        this.flickity = null
        this.index = 0
        this.isDragging = false
        this.dragEndTimer = null

        this.enquireHandler = null

        if (this.options.media) {
            this.enquireQuery =
                this.options.media in queries ? queries[this.options.media] : this.options.media
            this.enquireHandler = {
                match: this.attach,
                unmatch: this.detach,
            }
        }

        this.handleResize = debounce(this.handleResize, 300)
    }

    prepare() {
        if (this.enquireHandler) {
            enquire.register(this.enquireQuery, this.enquireHandler)
        } else {
            this.attach()
        }
        this.ref.slider.addEventListener('click', this.handleClick)
    }

    destroy() {
        if (this.enquireHandler) {
            enquire.unregister(this.enquireQuery, this.enquireHandler)
        } else {
            this.detach()
        }
    }

    attach = () => {
        if (this.flickity) {
            return
        }

        this.element.classList.add(STATES.READY)

        this.flickity = new Flickity(this.ref.slider, {
            cellSelector: '[data-ref="item"]',
            ...this.options,
            resize: false,
        })

        this.flickity.on('change', this.handleChange)
        this.flickity.on('dragStart', this.handleDragStart)
        this.flickity.on('dragEnd', this.handleDragEnd)

        this.resize()

        Scroll.on('resize', this.handleResize)

        this.handleChange(0)
        this.flickity.playPlayer(5000)

        this.dots.forEach((dot, index) => {
            toggleClass(dot, STATES.ACTIVE, this.flickity.selectedIndex === index)
            dot.addEventListener('click', () => this.handleDotClick(index))
        })
    }

    detach = () => {
        if (this.flickity) {
            this.flickity.destroy()
            this.flickity = null
        }

        this.element.classList.remove(STATES.READY)

        Scroll.off('resize', this.handleResize)
    }

    handleClick = (event) => {
        if (this.isDragging) {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    handleDragStart = () => {
        clearTimeout(this.dragEndTimer)
        this.isDragging = true
    }

    handleDragEnd = () => {
        clearTimeout(this.dragEndTimer)
        this.dragEndTimer = setTimeout(() => {
            this.isDragging = false
        }, 100)
    }

    handleChange = (index) => {
        this.dots.forEach((dot, dotIndex) => {
            toggleClass(dot, STATES.ACTIVE, dotIndex === index)
        })

        this.index = index
    }

    handleResize = () => {
        this.resize()
    }

    handleDotClick = (dotIndex) => {
        if (dotIndex != this.index) {
            this.index = dotIndex
            this.flickity.pausePlayer()

            this.flickity.select(dotIndex)
            this.flickity.playPlayer(5000)
        }
    }

    resize() {
        this.ref.dots.innerHTML = this.flickity.slides
            .map(() => '<span class="dot" data-ref="dot"></span>')
            .join('')
        this.dots = queryAll('[data-ref="dot"]', this.ref.dots)
        this.dots.forEach((dot, index) =>
            toggleClass(dot, STATES.ACTIVE, this.flickity.selectedIndex === index),
        )
    }
}
