import Component from '../core/Component'
import enquire from 'enquire.js'
import { queries } from '../core/config'

export default class FormSearch extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            input: null,
        }

        this.state = {
            isMobile: false,
            placeholder: this.ref.input.getAttribute('placeholder'),
            mobilePlaceholder: this.ref.input.getAttribute('data-mobile-placeholder'),
        }

        this.enquireHandlers = {
            match: this.attach,
            unmatch: this.detach,
        }
    }
    prepare() {
        enquire.register(queries.smallWideMax, this.enquireHandlers)
    }

    destroy() {
        enquire.unregister(queries.smallWideMax, this.enquireHandlers)
    }

    attach = () => {
        this.ref.input.setAttribute('placeholder', this.state.mobilePlaceholder)
    }

    detach = () => {
        this.ref.input.setAttribute('placeholder', this.state.placeholder)
    }
}
