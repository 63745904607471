import Component from '../core/Component'
import { enableScrolling, disableScrolling } from '../services/Viewport/scroll'
import support from '../utils/BrowserSupport'
import keycode from 'keycode'
import EventBus, { EVENTS } from '../core/EventBus'

export const STATUS = {
    ACTIVE: 'is-active'
}

export const defaults = {
    close: true
}

export default class Sidebar extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            background: null,
            layer: null,
            content: null
        }

        this.isOpen = false
        this.linkTag = null

        this.handleKeyDown = :: this.handleKeyDown
    }

    prepare() {
        this.ref.layer.addEventListener('click', :: this.handleClick)
        this.ref.background.addEventListener('click', :: this.close)

        EventBus.on(EVENTS.sidebarToggle, this.toggle)
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeyDown)
        EventBus.off(EVENTS.sidebarToggle, this.toggle)
    }


    handleClick(event) {
        const element = event.target

        if (element.closest('[data-link]') !== null) {
            this.close()
        }

        if (!this.ref.layer.contains(event.target)) {
            event.preventDefault()
            this.close()
        }
    }

    handleKeyDown(event) {
        if (keycode(event.keyCode) === 'esc') {
            this.close()
        }
    }

    toggle = (el) => {
        const linkTag = el.dataset.link
        this.linkTag = linkTag

        if (this.isOpen) {
            this.close()
        } else {
            this.open()
        }
    }

    open() {
        disableScrolling()
        this.element.classList.add(STATUS.ACTIVE)
        EventBus.emit(EVENTS.sidebarOpened)

        if (!this.isOpen) {
            document.addEventListener('keydown', this.handleKeyDown)
        }

        this.isOpen = true

        if (this.linkTag) {
            this.element.classList.add(`${this.linkTag}-active`)
        }
    }

    close() {
        enableScrolling()
        this.element.classList.remove(STATUS.ACTIVE)
        document.removeEventListener('keydown', this.handleKeyDown)
        EventBus.emit(EVENTS.sidebarClosed)
        this.isOpen = false

        this.element.classList.remove(`menu-active`)
        this.element.classList.remove(`tags-active`)
    }
}
