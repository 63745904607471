// general
import Antispam from '../../components/Antispam'

// project
import Accordion from './Accordion'
import FormSearch from './FormSearch'
import Input from './Input'
import Modal from './Modal'
import ModalOpener from './ModalOpener'
import Page from './Page'
import Search from './Search'
import Select from './Select'
import Sidebar from './Sidebar'
import SiteHeader from './SiteHeader'
import Share from './Share'
import Slider from './Slider'
import SuggestedSlider from './SuggestedSlider'
import SocialShare from './SocialShare'
import Sticky from './Sticky'
import SearchOpener from './SearchOpener'

const Components = {
    Accordion,
    Antispam,
    FormSearch,
    Input,
    Modal,
    ModalOpener,
    Page,
    Search,
    Select,
    Share,
    Sidebar,
    SiteHeader,
    Slider,
    SuggestedSlider,
    SocialShare,
    Sticky,
    SearchOpener,
}

export default Components
