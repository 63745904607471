// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'
import './plugins/giant.trigger'
import './plugins/giant.autosubmit'

// nette
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

import { USE_NATIVE_SCROLL } from './core/config'
import ScrollService from './services/Scroll'

if (USE_NATIVE_SCROLL) {
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}

import components from './components/'
import { loadComponents, removeComponents } from './core/componentUtils'

window.app = {
    components: components,
}

// import Swup from 'swup'
// import swupGtmPlugin from 'swup/plugins/swupGtmPlugin'
// import { runScriptsInsideElement } from './utils/script'

// const swup = new Swup({
//     LINK_SELECTOR:
//         'a[href^="' +
//         window.location.origin +
//         '"]:not([data-no-swup]):not([data-component]):not([data-ref]), a[href^="/"]:not([data-no-swup]):not([data-rel="news-modal"]):not([data-component]):not([data-ref]), a[href^="#"]:not([data-no-swup]):not([data-component]):not([data-ref])',
//     elements: ['#swup'],
//     animationSelector: '.Overlay',
//     debugMode: false,
//     doScrollingRightAway: false,
//     animateScroll: true,
//     preload: true,
//     cache: true,
//     plugins: [swupGtmPlugin],
// })

// // components
// swup.on('willReplaceContent', (event) => {
//     document.querySelectorAll('[data-swup]').forEach((element) => {
//         removeComponents(element)
//     })

//     swup.options.animateScroll = false
// })

// swup.on('contentReplaced', (event) => {
//     document.querySelectorAll('[data-swup]').forEach((element) => {
//         loadComponents(element)
//     })

//     //hubspot_form script po swupu na strance detailu
//     const hubspot_formEl = document.getElementById('hubspot_form')
//     if (hubspot_formEl) {
//         runScriptsInsideElement(hubspot_formEl)
//     }

//     Nette.reinit()
//     if ($.nette) {
//         $.nette.load()
//     }

//     // scrool to category on mobile
//     const categoriesBlock = document.querySelector('.CategorieListWrap')
//     if (categoriesBlock && window.innerWidth <= 575) {
//         const activeItem = document.querySelector('.is-active.CategorieList-Item')

//         setTimeout(() => {
//             categoriesBlock.scrollLeft = activeItem.offsetLeft - 20
//         }, 100)
//     }

//     setTimeout(() => {
//         swup.options.animateScroll = true
//     })
// })

// // categories click scroll
// swup.on('clickLink', (event) => {
//     const isLinkCategory = event.target.dataset.link
//     if (window.innerWidth >= 1024 && isLinkCategory) {
//         setTimeout(() => {
//             const categoriesBlock = document.querySelector('.CategorieListWrap').offsetTop
//             window.scrollTo({ top: categoriesBlock - 60, behavior: 'smooth' })
//         }, 100)
//     }
// })

loadComponents()
